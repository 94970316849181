@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--partners-slider-title {
  padding: 1.5rem 0 2.25rem;

  @include media-breakpoint-down(lg) {
    padding: 2.5rem 0 0.75rem;
  }

  .text--body {
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 400;

    @include media-breakpoint-down(lg) {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

.section--meta-hero + .section--partners-slider-title {
  @include media-breakpoint-down(lg) {
    padding-top: 0;
  }
}

.section--partners-slider {
  &.is-bordered {
    padding: 3rem 0;

    @include media-breakpoint-down(xxl) {
      padding: 2rem 0;
    }

    @include media-breakpoint-up(lg) {
      width: calc(100% - 1rem);
      margin: 0 auto;
      border-radius: $border-radius-xl;
    }
  }

  + .section--customers-slider {
    padding-top: 2.75rem;

    @include media-breakpoint-down(lg) {
      padding-top: 36px;
    }
  }

  &.jmp-ticker {
    .splide__slide {
      margin-right: 1rem !important;
    }
  }
}

.partners-slider {
  .splide__list {
    align-items: center;
  }

  &__item {
    display: inline-block;

    .star-icon {
      svg {
        max-width: 2.75rem;
        max-height: 2.75rem;
      }
    }
  }

  &__logo {
    width: auto;
    height: 4.375rem;

    @include media-breakpoint-down(md) {
      height: 52px;
    }
  }

  [data-bs-theme='dark'] {
    display: none;
  }
}

@include color-mode(dark) {
  .section--partners-slider {
    padding: 3rem 0;
    background-color: $white !important;

    @include media-breakpoint-down(xxl) {
      padding: 2rem 0;
    }
  }

  .partners-slider__item {
    [data-bs-theme='dark'] {
      display: block;
    }

    [data-bs-theme='light'] {
      display: none;
    }
  }
}
